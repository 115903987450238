import React from "react";
import styled from "styled-components";
import theme, { breakpoints } from "../style/theme"

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: auto;
  margin-top: 1rem;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  margin-bottom: calc(${(props) => props.border} * 2);
  padding: 2rem 0;
  background-color: ${theme.palette.primary.main};
  &:before, &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 1px;
    height: ${(props) => props.border};
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  &:before {
    border-right: 1px solid ${theme.palette.primary.main};
    bottom: -${(props) => props.border};
  }
  &:after {
    border-right: 1px solid ${theme.palette.white};
    bottom: 0;
  }
`;

const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 3rem 0;
  color: ${theme.palette.white};
  text-align: center;
  font-size: 3rem!important;
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 2.0rem!important;
  }
`

export const HeadCard = ({title}) => {
  return(
    <Wrapper border={"2rem"}>
      <Title>{title}</Title>
    </Wrapper>
  )
}