import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Layout from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { HeadCard } from "../components/HeadCard";
import { MDX } from "../components/MDX";

const useStyles = makeStyles(() => ({
  article: {
    width: "100%",
    lineHeight: 2.0,
    fontSize: "1.1rem",
    "& blockquote": {
      borderLeft: "3px solid #303032",
      marginLeft: -16,
      paddingLeft: 13,
      fontStyle: "italic"
    }
  }
}));

export default function PageTemplate({ data }) {
  const classes = useStyles();
  const { mdx } = data;
  const {
    frontmatter: { featuredImage, title, description },
    body
  } = mdx;

  const tags = data.allMdx.group;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const columns = data.site.siteMetadata.components.footer.columns;

  return (
    <Layout
      title={title}
      description={description}
      image={featuredImage.childImageSharp.fluid.src}
      shownSideBar={false}
    >
      <Breadcrumbs
        links={[
          {
            url: null,
            title: `${title}`,
          }
        ]}
      />
      <article className={classes.article}>
        <HeadCard title={title} />
        <MDX siteUrl={siteUrl} tags={tags} columns={columns} body={body} />
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($postId: String!) {
    mdx(frontmatter: { id: { eq: $postId } }) {
      body
      frontmatter {
        id
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        components {
          footer {
            columns {
              heading
              links {
                title
                url
              }
            }
          }
        }
      }
    }
    allMdx {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`;
